/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  useDaily,
  useVideoTrack,
  useAudioTrack,
  useDevices,
} from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
// import ReactionWinnerConfetti from "../../PlayerTileReaction/ReactionWinnerConfetti";

function Participant({ player, gameData }) {
  const { user, oneToOneRoom, pokerTableVolume, speaker, mirrorVideo } = useContext(UserContext);
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();
  // const reaction = reactions.find(
  //   (el) => el.role === "user" && player?.id === el.userId
  // );
  const isMirrorUser = user?.id === player?.id && mirrorVideo;
  const { hasCamError } = devices || {};

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  const handleEndCall = () => {
    socket.emit("oneToOneLeaveRequest", {
      gameId: gameData.id,
      roomId: gameData?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
  };

  useEffect(() => {
    if (!callObject) return;
    callObject.on("participant-joined", (event) => {
      if (player?.id === event.participant.user_name) {
        callObject.updateParticipant(event.participant.session_id);
        socket.emit("playerMicChange", {
          gameId: gameData.id,
          userId: player?.id,
          isAudio: player?.isAudio,
        });
      }
    });
  }, [callObject]);
  useEffect(() => {
    if (videoTrack.persistentTrack && videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (audioTrack.persistentTrack && audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(player.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      audioRef.current.volume = isParticipantInCall && isParticipantInCall.roomId?.includes(user.id)
        ? pokerTableVolume
        : isParticipantInCall || isUserInCall
          ? 0.0
          : pokerTableVolume;

      audioRef.current.srcObject = new MediaStream([
        audioTrack.persistentTrack,
      ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    pokerTableVolume,
    audioTrack.subscribed,
  ]);

  return (
    <>
      <div className="player-box-iframe ">
        {remoteParticipant
        && remoteParticipant?.video
        && (!videoTrack?.isOff || !hasCamError)
        && videoTrack?.state === "playable" ? (
          <>
            {oneToOneRoom?.find((room) => room.roomId?.includes(player.id))
            && user?.id === player?.id ? (
              <div className="call-in-progress">
                <span>Call In Progress</span>
                <div />
              </div>
              ) : (
                <video
                  style={{
                    width: "100%",
                    transform: isMirrorUser ? "scale(-1, 1)" : "",
                    filter: isMirrorUser ? "FlipH" : "",
                  }}
                  ref={videoRef}
                  autoPlay
                  controls={false}
                  preload=""
                  muted
                />
              )}
            {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
          </>
          ) : (
            <>
              <div className="main-player-camoff">
                <img
                  src={player?.avatar}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                  }}
                  alt=""
                />
              </div>
              {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
            </>
          )}
        {oneToOneRoom?.find((room) => room.roomId?.includes(player.id))
        && user?.id === player?.id ? (
          <div className="end-private-call">
            <Button onClick={handleEndCall}>End Call</Button>
          </div>
          ) : (
            ""
          )}
        {remoteParticipant?.user_name !== user?.id ? (
          <audio
            ref={audioRef}
            autoPlay
            muted={remoteParticipant?.local || !speaker}
            id={player.id}
          />
        ) : (
          ""
        )}
      </div>

      <div className="player-box-iframe multi-table-avatar">
        {!remoteParticipant ? (
          <div className="main-player-camoff">
            <img
              src={player?.avatar}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
              }}
              alt=""
            />
            <span>{player?.name}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

Participant.defaultProps = {
  player: null,
  gameData: {},
};

Participant.propTypes = {
  player: PropTypes.object,
  gameData: PropTypes.object,
};

export default Participant;
