/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useActiveSpeakerId,
  useAudioTrack,
  useDaily,
  useDevices,
  useVideoTrack,
} from "@daily-co/daily-react";
import UserContext from "../../../context/UserContext";
import MuteIcon from "../../../IconComponents/MuteIcon";

function OneToOneUser({ allGameData }) {
  const { user } = useContext(UserContext);
  const [callPlayers, setCallPlayers] = useState([]);

  useEffect(() => {
    const myRoomCall = allGameData.oneToOneRoom.filter(
      (call) => call.requestedBy === user.id || call.requestedTo === user.id
    );
    if (myRoomCall.length > 0) {
      const { requestedBy, requestedTo } = myRoomCall[0];
      const filterPlayers = allGameData.players.filter(
        (p) => p.id === requestedBy || p.id === requestedTo
      );
      const filterViewer = allGameData.viewers.filter(
        (p) => p.id === requestedBy || p.id === requestedTo
      );
      const filterSpectators = allGameData.spectators.filter(
        (p) => p.id === requestedBy || p.id === requestedTo
      );

      const finalPlayer = [
        ...filterPlayers,
        ...filterViewer,
        ...filterSpectators,
      ];
      setCallPlayers(finalPlayer);
    }
  }, []);

  return (
    <div className="rail-slider-content">
      {callPlayers.map((callPlayer) => (
        <OneToOneUserList player={callPlayer} gameData={allGameData} />
      ))}
    </div>
  );
}

OneToOneUser.defaultProps = {
  allGameData: {},
};

OneToOneUser.propTypes = {
  allGameData: PropTypes.object,
};

export default OneToOneUser;

function OneToOneUserList({ player }) {
  const { user, oneToOneRoom, pokerTableVolume, speaker, mirrorVideo } = useContext(UserContext);
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();
  const activeSpeakerId = useActiveSpeakerId();

  const isMirrorUser = user?.id === player?.id && mirrorVideo;
  const { hasCamError } = devices || {};

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);
  useEffect(() => {
    if (videoTrack.persistentTrack && videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (audioTrack.persistentTrack && audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(player.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      audioRef.current.volume = isParticipantInCall && isParticipantInCall.roomId?.includes(user.id)
        ? pokerTableVolume
        : isParticipantInCall || isUserInCall
          ? 0.0
          : pokerTableVolume;

      audioRef.current.srcObject = new MediaStream([
        audioTrack.persistentTrack,
      ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    pokerTableVolume,
    audioTrack.subscribed,
  ]);
  const activeSpeaker = useMemo(
    () => activeSpeakerId === remoteParticipant?.session_id && player?.isAudio,
    [activeSpeakerId, remoteParticipant?.session_id, player?.isAudio]
  );
  return (
    <div
      className={`rail-player-wrapper  ${activeSpeaker ? "player-voice" : ""}`}
    >
      <div className="player-box-iframe full-height">
        {remoteParticipant
        && remoteParticipant?.video
        && (!videoTrack?.isOff || !hasCamError)
        && videoTrack?.state === "playable" ? (
          <>
            <video
              style={{
                width: "100%",
                height: "100%",
                transform: isMirrorUser ? "scale(-1, 1)" : "",
                filter: isMirrorUser ? "FlipH" : "",
              }}
              ref={videoRef}
              autoPlay
              controls={false}
              preload=""
              muted
            />
            {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
          </>
          ) : (
            <div className="main-player-camoff">
              <img
                src={player?.avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                }}
                alt=""
              />
            </div>
          )}
        {remoteParticipant?.user_name !== user?.id ? (
          <audio
            ref={audioRef}
            autoPlay
            muted={remoteParticipant?.local || !speaker}
            id={player.id}
          />
        ) : (
          ""
        )}
      </div>
      {/* Already Implemented */}
      <div className="rail-username">
        <span title={player?.name}>
          {player?.name}
          {" "}
          {audioTrack?.state === "playable" ? "" : <MuteIcon />}
        </span>
      </div>
    </div>
  );
}

OneToOneUserList.defaultProps = {
  player: {},
};

OneToOneUserList.propTypes = {
  player: PropTypes.object,
};
