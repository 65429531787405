/* eslint-disable jsx-a11y/media-has-caption */
import {
  useAudioTrack,
  useDaily,
  useDevices,
  useVideoTrack,
  useActiveSpeakerId,
} from "@daily-co/daily-react";
import { useEffect, useRef, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";

import PropsTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import cross from "../../../assets/images/profile/cross.svg";
import ProfileModal from "../../profile/profileModal";
import soundMute from "../../../assets/images/waitingroom/sound.svg";
import BarRoomSetting from "../../barRoom/settings";
import socket from "../../../config/socket";

function WaitingRoomParticipant({
  user,
  position,
  isHost,
  isBroadcasting,
  sessionID,
}) {
  const callObject = useDaily();
  const videoRef = useRef();
  const audioRef = useRef();
  const { hasCamError } = useDevices();
  const {
    user: authUser,
    setShowProfileModal,
    showProfileInfo,
    setShowProfileInfo,
    pokerTableVolume,
    speaker,
    responsiveScale,
    mirrorVideo,
    showBroadcastControl,
  } = useContext(UserContext);
  const handleClose = () => setShowProfileInfo("");
  const handleShow = (e) => {
    setShowProfileInfo(e);
  };
  const { gameId } = useParams();
  const [showSetting, setShowSetting] = useState(false);
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);
  //  const isShowStream = sessionID === null ? (isHost ? !isBroadcasting : true) : isBroadcasting;
  //  const isShowStream = isHost ? !isBroadcasting : true;
  const isMirrorUser = user?.id === authUser?.id && mirrorVideo;
  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === user?.id
  );
  const activeSpeakerId = useActiveSpeakerId();

  if (isHost && sessionID === null) {
    localStorage.setItem("sessionID", remoteParticipant?.session_id);
  }

  const videoTrack = useVideoTrack(
    sessionID === null ? remoteParticipant?.session_id : sessionID
  );
  const audioTrack = useAudioTrack(
    sessionID === null ? remoteParticipant?.session_id : sessionID
  );
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  //  callObject.on("participant-joined", (event) => {
  //    if (user?.id === event.participant.user_name) {
  //      callObject.updateParticipant(event.participant.session_id);
  //      socket.emit("playerMicChange", {
  //        gameId,
  //        userId: user?.id,
  //        isAudio: user?.isAudio,
  //      });
  //    }
  //  });

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
    if (user?.id === authUser?.id && !localStorage.getItem("isCameraOn")) {
      localStorage.setItem("isCameraOn", true);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoTrack.subscribed,
    isBroadcasting,
    showBroadcastControl,
  ]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      audioRef.current.volume = pokerTableVolume;
      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
    if (user?.id === authUser?.id && !localStorage.getItem("isMicOn")) {
      const isMute = isBroadcasting ? !!isHost : true;
      localStorage.setItem("isMicOn", isMute);
    }
  }, [audioTrack]);
  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);
  useEffect(() => {
    callObject.on("participant-joined", (event) => {
      if (user?.id === event.participant.user_name) {
        callObject.updateParticipant(event.participant.session_id);
        socket.emit("playerMicChange", {
          gameId,
          userId: user?.id,
          isAudio: user?.isAudio,
        });
      }
    });
  }, [callObject]);

  if (!user) {
    return (
      <div className={`wr-camera-box content-${position + 1}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <g clipPath="url(#clip0_11117_245)">
            <path
              d="M30 33H6V30C6 28.0109 6.79018 26.1032 8.1967 24.6967C9.60322 23.2902 11.5109 22.5 13.5 22.5H22.5C24.4891 22.5 26.3968 23.2902 27.8033 24.6967C29.2098 26.1032 30 28.0109 30 30V33ZM18 19.5C16.8181 19.5 15.6478 19.2672 14.5558 18.8149C13.4639 18.3626 12.4718 17.6997 11.636 16.864C10.8003 16.0282 10.1374 15.0361 9.68508 13.9442C9.23279 12.8522 9 11.6819 9 10.5C9 9.3181 9.23279 8.14778 9.68508 7.05585C10.1374 5.96392 10.8003 4.97177 11.636 4.13604C12.4718 3.30031 13.4639 2.63738 14.5558 2.18508C15.6478 1.73279 16.8181 1.5 18 1.5C20.3869 1.5 22.6761 2.44821 24.364 4.13604C26.0518 5.82387 27 8.11305 27 10.5C27 12.8869 26.0518 15.1761 24.364 16.864C22.6761 18.5518 20.3869 19.5 18 19.5Z"
              fill="#4c505e"
            />
          </g>
          <defs>
            <clipPath id="clip0_11117_245">
              <rect width="36" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
  const renderBroadcastingView = (isHostView) => (
    <div className="call-in-progress">
      <span>
        {isHostView ? "Your" : "Host"}
        {" "}
        are broadcasting
      </span>
      <div />
    </div>
  );
  return (
    <div className={`wr-camera-box content-${position + 1} camera-active`}>
      {remoteParticipant
      && !videoTrack.isOff
      && !hasCamError
      && videoTrack.state === "playable" ? (
          isHost && showBroadcastControl ? (
            renderBroadcastingView(true)
          ) : isBroadcasting ? (
            renderBroadcastingView(false)
          ) : (
            <video
              style={{
                width: "100%",
                transform: isMirrorUser ? "scale(-1, 1)" : "",
                filter: isMirrorUser ? "FlipH" : "",
              }}
              ref={videoRef}
              autoPlay
              playsInline
              controls={false}
              preload=""
              muted
            />
          )
        ) : isHost && showBroadcastControl ? (
          renderBroadcastingView(true)
        ) : isBroadcasting ? (
          renderBroadcastingView(false)
        ) : (
          <img
            className="cameraOff"
            src={
            user?.avatar
            || "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png"
          }
            onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
        )}

      {remoteParticipant?.user_name !== authUser?.id && (
        <audio
          ref={audioRef}
          autoPlay
          muted={!remoteParticipant?.audio || !speaker}
        />
      )}
      <div
        className={`player-dropdown ${
          activeSpeakerId === remoteParticipant?.session_id
          && remoteParticipant?.tracks?.audio?.state !== "off"
          && user?.isAudio
            ? "player-btn-dropdown"
            : ""
        } `}
      >
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {/* Add this class below class to animate the dot icons "dot-animate" */}
            <div
              className={`dot-flashing ${
                activeSpeakerId === remoteParticipant?.session_id
                && remoteParticipant?.tracks?.audio?.state !== "off"
                && user?.isAudio
                  ? "dot-animate"
                  : ""
              }`}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {user.id !== authUser.id ? (
              <Dropdown.Item
                as="button"
                className="dropdown-item"
                onClick={() => handleShow(user?.id)}
                role="presentation"
              >
                View profile
              </Dropdown.Item>
            ) : (
              ""
            )}
            {user.id === authUser.id ? (
              <>
                <Dropdown.Item
                  as="button"
                  className="dropdown-item"
                  onClick={() => navigate("?modal=profile")}
                  role="presentation"
                >
                  Edit Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="dropdown-item"
                  onClick={handleShowSetting}
                  role="presentation"
                >
                  Manage Audio/Video
                </Dropdown.Item>
              </>
            ) : (
              ""
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="wr-camera-name">
        <p>{user?.name}</p>
        {audioTrack.isOff ? <img src={soundMute} alt="mute" /> : ""}
      </div>

      {isHost && (
        <div className="wr-host-tag">
          <p>HOST</p>
        </div>
      )}
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        centered
        className="bar-room-setting-popup"
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>

      {showProfileInfo === user?.id && (
        <div className="profile-viewer">
          <span
            className="close-profile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="" />
          </span>
          <ProfileModal user={user} />
        </div>
      )}
    </div>
  );
}

WaitingRoomParticipant.defaultProps = {
  user: null,
  isHost: true,
  isBroadcasting: false,
  sessionID: null,
};

WaitingRoomParticipant.propTypes = {
  user: PropsTypes.object,
  position: PropsTypes.number.isRequired,
  isHost: PropsTypes.bool,
  isBroadcasting: PropsTypes.bool,
  sessionID: PropsTypes.string,
};

export default WaitingRoomParticipant;
