import { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import { Helmet } from "react-helmet";
// import Lottie from "react-lottie";
import PropsTypes from "prop-types";
import UserContext from "../../../context/UserContext";
import MultiPlayersView from "./MultiPlayersView";
import socket from "../../../config/socket";
import "./style.css";
import GameCreation from "../../gameCreation/gamecreation";
// import { getAllGamePlayers } from "../../../utils/Api";
// import Layout from "../../layout/layout";
// import loadericon from "../../../assets/images/dealer/loader.json";

let tRound = null;
let tPlayer = null;
let game;
const d = 0;
let staticPlayersLeft = [];
let staticPlayersRight = [];

// const LoaderAnimation = {
//  loop: true,
//  autoplay: true,
//  animationData: loadericon,
// };

function MultiTablePlayerGameView({ gameId }) {
  const RoundName = {
    0: "players",
    1: "preflopround",
    2: "flopround",
    3: "turnround",
    4: "riverround",
    5: "showdown",
  };
  const {
    user,
    setGameEvent,
    allGamesData,
    setAllGamesData,
    gameEvent,
  } = useContext(UserContext);
  const gameData = allGamesData[gameId];
  const [currentPlayer, setCurrentPlayer] = useState({});
  const [remainingTime, setRemainingTime] = useState(0);
  const [recentPlayer, setRecentPlayer] = useState();
  const [showGameCreation, setShowGameCreation] = useState(false);
  const [playersLeft, setPlayerLeft] = useState([]);
  const [winners, setWinners] = useState([]);
  const [playersRight, setPlayersRight] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [action, setAction] = useState({});
  const [gamePaused, setGamePaused] = useState("");
  const [timer, setTimer] = useState({});
  const timerData = timer[gameId];
  const [bet, setBet] = useState(false);
  const [raise, setRaise] = useState(false);
  //  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [oneToOneParticipants] = useState({});
  const [openAction, setOpenAction] = useState({
    bet: false,
    call: false,
    raise: false,
    check: false,
    allin: false,
    fold: false,
  });
  const handlePlaySound = (value) => {
    setIsPlaying(true);
    const ele = document.getElementById(value);
    if (isPlaying) {
      ele.play();
    }
  };
  const handleStopSound = (value) => {
    setIsPlaying(false);
    const ele = document.getElementById(value);
    if (!isPlaying) {
      ele.pause();
    }
  };

  //  const getAllPlayers = async () => {
  //    const usersIds = allGamesData[gameId]?.players?.map((el) => el.id) || [];
  //    const users = await getAllGamePlayers(usersIds);
  //    if (users.length) {
  //      setGamePlayers((data) => {
  //        const uniqueIds = new Set(data?.map((item) => item.id));
  //        const uniqueUsers = users?.filter(
  //          (eachUser) => !uniqueIds.has(eachUser.id)
  //        );
  //        return [...data, ...uniqueUsers];
  //      });
  //    }
  //  };

  useEffect(() => {
    if (user && gameId) {
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameData: !!(gameData && Object.keys(gameData)?.length),
        userId: user?.id,
      });
    }
    // if (gameData?.players?.length) {
    //  getAllPlayers();
    // }
  }, [gameData?.players?.length]);

  useEffect(() => {
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            tPlayer = null;
            tRound = null;
            socket.emit("checkTable", {
              gameId,
              gameData: !!(gameData && Object.keys(gameData)?.length),
              userId: user?.id,
            });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    return () => {
      socket.io.off("close");
    };
  }, []);

  const updatePlayers = (pls) => {
    if (pls?.length === 0 || !pls) {
      return;
    }
    const pl = [...pls];
    let pRight = [];
    let pleft = [];
    const leftLimit = Math.floor(pl.length / 2);
    const rightLimit = pl.length - leftLimit;

    const currPlayer = pl?.findIndex((el) => el.id === user?.id);
    if (currPlayer >= 0) {
      let count = 0;
      let startIndex = currPlayer - (rightLimit - 1);
      if (startIndex < 0) startIndex += pl.length;

      while (count < pl.length) {
        count += 1;
        if (pRight?.length === rightLimit) {
          pleft = [pl[startIndex], ...pleft];
        } else if (pRight?.length < rightLimit) {
          pRight = [...pRight, pl[startIndex]];
        }
        if (pleft === leftLimit && pRight === rightLimit) break;
        startIndex = (startIndex + 1) % pl.length;
      }
    } else {
      pRight = pl.slice(0, Math.ceil(pl.length / 2));
      pleft = pl.slice(Math.ceil(pl.length / 2)).reverse();
    }

    setPlayerLeft(pleft);
    setPlayersRight(pRight);

    if (staticPlayersLeft.length !== pleft.length) {
      staticPlayersLeft = pleft;
    }
    if (staticPlayersRight.length !== pRight.length) {
      staticPlayersRight = pRight;
    }
  };

  const handleTentativeAction = (player) => {
    let event;
    const { tentativeAction } = player;
    if (player.tentativeAction.includes(" ")) {
      const [event1] = tentativeAction.split(" ");
      event = event1;
    } else {
      event = tentativeAction;
    }

    switch (event) {
      case "check":
        socket.emit("docheck", { gameId, userId: player.id });
        break;
      case "fold":
        socket.emit("dofold", { gameId, userId: player.id });
        break;
      case "check/fold":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else {
          socket.emit("dofold", { gameId, userId: player.id });
        }
        break;
      case "call":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player.id,
            amount: game.raiseAmount,
          });
        }
        break;
      case "callAny":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player.id,
            amount: game.raiseAmount,
          });
        }
        break;
      case "allin":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else {
          socket.emit("doallin", {
            gameId,
            userId: player.id,
            amount: player.wallet,
          });
        }
        break;
      default:
        return "";
    }
  };
  const handleActionButton = (currentPl) => {
    setBet(false);
    setRaise(false);
    const currentAction = { ...openAction };
    const { pot, wallet } = currentPl || {};
    const { raiseAmount, lastAction, runninground: round } = game || {};
    currentAction.fold = true;
    if (round === 1) {
      if (raiseAmount === pot) {
        // check true
        currentAction.check = true;
        currentAction.call = false;
      } else if (wallet + pot > raiseAmount) {
        // call true
        currentAction.call = true;
        currentAction.bet = false;
        currentAction.check = false;
      }
      if (wallet + pot > raiseAmount) {
        // range true
        currentAction.raise = true;
        currentAction.bet = false;
      } else if (wallet + pot <= raiseAmount) {
        // allin true
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }
    }

    if (round >= 2) {
      if (lastAction === "check" || raiseAmount === pot) {
        currentAction.check = true;
      }

      if (lastAction === "check") {
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
          currentAction.check = false;
        } else {
          currentAction.call = false;
          currentAction.bet = true;
          currentAction.raise = false;
        }
      } else {
        if (wallet + pot > raiseAmount) {
          currentAction.call = true;
          currentAction.bet = false;
        }
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
        }
        if (raiseAmount < wallet + pot) {
          currentAction.allin = false;
          currentAction.bet = false;
          currentAction.raise = true;
        }
      }
      if (wallet + pot <= raiseAmount) {
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }

      if (lastAction !== "check" && pot !== raiseAmount) {
        currentAction.check = false;
      }
    }
    setOpenAction(() => currentAction);
  };

  useEffect(() => {
    socket.on("connect", () => {
      // eslint-disable-next-line no-console
    //  console.log("connected");
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameEventId: gameEvent?.games?.find(
          (eachGameId) => eachGameId === gameId
        ),
        gameData: !!(gameData && Object.keys(gameData).length),
        userId: user.id,
      });
    });
    socket.on("updateGame", (data) => {
      let roomData;
      if (data?.game?.id === gameId) {
        roomData = data?.game;
        // setIsLoading(false);
        if (
          game?.pot === 0
          && game?.sidePots?.length === 0
          && game.runninground === 5
          && game?.winnerPlayer?.length
        ) {
          setAllGamesData((prev) => ({
            ...prev,
            [data?.game?.id]: data?.game,
          }));
          if (data?.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        } else {
          setWinners([]);
          game = data.game;
          setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
          if (data.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        }
        setGamePaused("");
        if (game?.pause) {
          setGamePaused("gamePaused");
        } else if (
          game?.runninground === 1
          && game?.preflopround?.find((pl) => pl.cards.length !== 2 && pl.playing)
        ) {
          setGamePaused("usercardScanning");
        } else if (game.runninground === 2 && game.communityCard.length < 3) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 3 && game.communityCard.length < 4) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 4 && game.communityCard.length < 5) {
          setGamePaused("communityCardScanning");
        } else {
          setGamePaused("");
        }
        if (roomData?.runninground === 0) {
          updatePlayers(roomData.players);
          //  if (!data.gameEvent) return;
          //  const conditionCheck = (field, obj = roomData) =>
          //    obj[field]?.find(
          //      (el) => el?.id?.toString() === user?.id?.toString()
          //    );
          //  if (
          //    user.role === "user"
          //  && !(
          //    data.gameEvent?.games?.find(
          //      (g) =>
          //        conditionCheck("players", g) || conditionCheck("viewers", g)
          //    )
          //     || conditionCheck("players")
          //     || conditionCheck("viewers")
          //  )
          //  ) {
          //    localStorage.removeItem("videoKey");
          //    return Navigate(`/user/waiting-room/${gameId}`);
          //  }
        } else if (roomData.runninground === 1) {
          updatePlayers(roomData.preflopround);
        } else if (roomData.runninground === 2) {
          updatePlayers(roomData.flopround);
        } else if (roomData.runninground === 3) {
          updatePlayers(roomData.turnround);
        } else if (roomData.runninground === 4) {
          updatePlayers(roomData.riverround);
        } else if (roomData.runninground === 5) {
          updatePlayers(roomData.showdown);
        }
      }
    });

    socket.on("actionperformed", (data) => {
      if (data?.gameId === gameId) {
        setRecentPlayer(data);
        if (data.action === "fold") {
          setAction(data);
          setTimeout(() => setAction({}), 3000);
        }
      }
    });

    socket.on("newhand", (data) => {
      if (data?.game?.id === gameId) {
        game.gamestart = false;
        game.pot = 0;
        game.sidePots = [];
        tPlayer = null;
        tRound = null;
        const p = [];
        game?.showdown?.forEach((pl) => {
          const newP = data?.game?.players.find((newPl) => newPl.id === pl.id);
          if (newP) {
            p.push({ ...newP, cards: pl.cards });
          }
        });
        if (data?.gameEvent) {
          setGameEvent(data.gameEvent);
        }
        if (data?.game?.players?.length === 1) {
          const f = {
            ...game,
            players: p,
            viewers: data?.game?.viewers,
            viewersRank: data?.game?.viewersRank,
          };
          updatePlayers(p);
          setAllGamesData((prev) => ({
            ...prev,
            [data.game.id]: { ...f },
          }));
          setGameEvent(data.gameEvent);
        }
      }
    });

    socket.on("scanCardForUser", (data) => {
      if (data?.game?.id === gameId && game && game?.id === data?.game?.id) {
        game = data.game;
        updatePlayers(game?.preflopround);
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (data?.gameEvent) {
          setGameEvent(data.gameEvent);
        }
        setCurrentPlayer({});
        setShowCards(false);
        setWinners([]);
        setGamePaused("usercardScanning");
      }
    });

    // card scans on seach round socket
    socket.on("scanCommunityCard", (data) => {
      if (data?.game?.id === gameId && game && game?.id === data?.game?.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        const obj = {};
        obj[data.game.id] = 0;
        setTimer({ ...timer, ...obj });
        setCurrentPlayer({});
        if (game.runninground === 0) {
          updatePlayers(game.players);
        } else if (game.runninground === 1) {
          updatePlayers(game.preflopround);
        } else if (game.runninground === 2) {
          updatePlayers(game.flopround);
        } else if (game.runninground === 3) {
          updatePlayers(game.turnround);
        } else if (game.runninground === 4) {
          updatePlayers(game.riverround);
        } else if (game.runninground === 5) {
          updatePlayers(game.showdown);
        }
        setGamePaused("communityCardScanning");
      }
    });

    socket.on("winner", (data) => {
      if (data?.game?.id === gameId && game && game?.id === data?.game?.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (game?.winnerPlayer[0]?.handName) {
          setShowCards(true);
        }
        updatePlayers(game?.showdown);
        setCurrentPlayer({});
        setWinners(game?.winnerPlayer);
      }
    });

    socket.on("roomResume", (data) => {
      if (data?.game?.id === gameId && game && game?.id === data?.game?.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("");
      }
    });

    socket.on("roomPaused", (data) => {
      if (data?.game?.id === gameId && game && game?.id === data?.game?.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("gamePaused");
      }
    });
  }, []);

  useEffect(() => {
    socket.on("timer", (data) => {
      if (data?.gameId?.toString() === gameId?.toString()) {
        setRemainingTime(data.playerchance);
        if (data.timerType === "timebank") {
          setTimer(data.maxtimer);
        }
        if (data.playerchance === 5) {
          handlePlaySound("timer-out");
        }
        game.timer = data.maxtimer;
        if (tPlayer !== data.id || tRound !== data.runninground || d === 5) {
          if (timer === 0) {
            setTimer(game && game.timer);
          }
          const activePlayer = game[RoundName[data?.runninground]]?.find(
            (ele) => ele.id === data.id
          );
          if (activePlayer?.tentativeAction && activePlayer?.id === user?.id) {
            handleTentativeAction(activePlayer);
            setCurrentPlayer(activePlayer);
          } else {
            setCurrentPlayer(activePlayer);
            handleActionButton(activePlayer);
          }
          if (data.playerchance === 5 && activePlayer?.id === user.id) {
            handlePlaySound("timer-out");
          }
          setRecentPlayer((old) => old);
        }
        tPlayer = data.id;
        tRound = data.runninground;
      }
    });
  }, [gameId]);

  useEffect(() => {
    if (gameData?.players?.length === 0) {
      setPlayerLeft([]);
      setPlayersRight([]);
      staticPlayersLeft = [];
      staticPlayersRight = [];
    }
  }, [gameData?.players?.length]);

  return (
  // <Layout>
  //  <>
  //    <Helmet>
  //      <body className="game-view" />
  //    </Helmet>
  //    {isLoading ? (
  //      <div className="loader">
  //        <Lottie options={LoaderAnimation} />
  //      </div>
  //    ) : (
  //      <div className="player-game-view tournament-game-view">
  //        <div className="container">
  //          <div className="player-game-view-wrapper">
  //            <MultiPlayersView
  //              playersLeft={playersLeft}
  //              playersRight={playersRight}
  //              staticPlayersLeft={staticPlayersLeft}
  //              staticPlayersRight={staticPlayersRight}
  //              action={action}
  //              currentPlayer={currentPlayer}
  //              timer={timerData}
  //              remainingTime={remainingTime}
  //              showCards={showCards}
  //              recentPlayer={recentPlayer}
  //              winners={winners}
  //              oneToOneParticipants={oneToOneParticipants}
  //              openAction={openAction}
  //              setOpenAction={setOpenAction}
  //              bet={bet}
  //              setBet={setBet}
  //              raise={raise}
  //              setRaise={setRaise}
  //              setTimer={setTimer}
  //              gamePaused={gamePaused}
  //              gameData={allGamesData[gameId]}
  //              handleStopSound={handleStopSound}
  //            />
  //          </div>
  //        </div>
  //        <Modal
  //          show={showGameCreation}
  //          onHide={() => setShowGameCreation(false)}
  //          className="game-creation-modal"
  //          centered
  //        >
  //          <Modal.Body>
  //            <GameCreation handleClose={() => setShowGameCreation(false)} />
  //          </Modal.Body>
  //        </Modal>
  //      </div>
  //    )}
  //  </>
  // </Layout>
    <div className="player-game-view tournament-game-view">
      <div className="container">
        <div className="player-game-view-wrapper">
          <MultiPlayersView
            playersLeft={playersLeft}
            playersRight={playersRight}
            staticPlayersLeft={staticPlayersLeft}
            staticPlayersRight={staticPlayersRight}
            action={action}
            currentPlayer={currentPlayer}
            timer={timerData}
            remainingTime={remainingTime}
            showCards={showCards}
            recentPlayer={recentPlayer}
            winners={winners}
            oneToOneParticipants={oneToOneParticipants}
            openAction={openAction}
            setOpenAction={setOpenAction}
            bet={bet}
            setBet={setBet}
            raise={raise}
            setRaise={setRaise}
            setTimer={setTimer}
            gamePaused={gamePaused}
            gameData={allGamesData[gameId]}
            handleStopSound={handleStopSound}
          />
        </div>
      </div>
      <Modal
        show={showGameCreation}
        onHide={() => setShowGameCreation(false)}
        className="game-creation-modal"
        centered
      >
        <Modal.Body>
          <GameCreation handleClose={() => setShowGameCreation(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

MultiTablePlayerGameView.defaultProps = {
  gameId: undefined,
};

MultiTablePlayerGameView.propTypes = {
  gameId: PropsTypes.string,
};
export default MultiTablePlayerGameView;
