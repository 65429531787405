/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from "react";
// import DailyIframe from "@daily-co/daily-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { useDaily } from "@daily-co/daily-react";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
// import Button from "react-bootstrap/Button";
// import logo from "../../../assets/images/waitingroom/Logo.svg";
import loaderIcon from "../../../assets/images/dealer/loader.json";
import UserContext from "../../../context/UserContext";
import WaitingRoomCamera from "./waitingroom";
import socket from "../../../config/socket";
import WaitingRoomAction from "./WaitingRoomAction";
import JoinErrorModal from "../waitingRoomPopup/joinErrorModal";
import Sidebar from "../../layout/sidebar";
import CreateProfileModal from "../../profile/registerProfileModal";
import intSignConvert from "../../../utils/intSignConvert";
import ToastError from "../../toast/erros";
import BarRoomSetting from "../../barRoom/settings";
// import CrossIcon from "../../../IconComponents/crossIcon";
import "./style.css";
import HostControlCall from "./hostControlCall";
import WaitingRoomSideBar from "./WaitingRoomSideBar";
import HostBroadcast from "../../broadcast";

let game;
// let newCallObject;
function WaitingRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    user,
    showProfileModal,
    setShowProfileModal,
    messages,
    setMessages,
    responsiveScale,
    setGameEvent,
    setShowCountDown,
    setShowIntroVideo,
    showBroadcastControl,
    setShowBroadcastControl,
    gameEvent,
    showIntroVideo,
  } = useContext(UserContext);
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const { gameId } = useParams();
  const [gameData, setGameData] = useState({});
  const [joinError, setJoinError] = useState(false);
  const [waitingPlayers, setWaitingPlayers] = useState([]);
  const [isHostData, setIsHostData] = useState({});
  //  const [callObject, setCallObject] = useState();
  const [showIntro, setShowIntro] = useState(false);
  const [leaveRoom, setLeaveRoom] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [sidebarExpand, setSidebarExpand] = useState(false);
  const [chats, setChats] = useState([]);

  const callObject = useDaily();

  const LoaderAnimation = {
    loop: true,
    autoplay: true,
    animationData: loaderIcon,
  };

  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);

  const handleCloseJoinError = () => {
    navigate("/");
  };

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, []);

  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  useEffect(() => {
    const handleConnect = () => {
    //  console.log("connected");
      socket.emit("checkTable", {
        gameId,
        userId: user.id,
      });
    };

    const handleMoveToInside = (id) => {
      window.location.href = `/user/main-game/${id}`;
    };
    const handleWtUpdate = (data) => {
      if (data?.user?.id === user?.id) {
        localStorage.removeItem("timerCount");
        localStorage.removeItem("dealerMuteAll");
        // localStorage.removeItem("isMicOn");
        // localStorage.removeItem("isCameraOn");
        localStorage.removeItem("isActed");
      }
      setIsLoading(false);
      localStorage.removeItem("timerCount");
      localStorage.removeItem("dealerMuteAll");

      const checkSpecInGame = data.gameEvent?.games?.find((el) =>
        el?.spectators?.find((pl) => pl.id === user?.id)
      );

      if (user?.role !== "dealer" && checkSpecInGame) {
        window.location.href = `/user/main-game/${checkSpecInGame?.id}`;
      }

      if (!game) {
        setGameData(data.game);
      }
      game = data.game;
      setGameEvent(data?.gameEvent);
      setWaitingPlayers(game.waitingPlayers);
      const obj = {
        host: data?.gameEvent?.host?.toLowerCase(),
        isBroadcasting: data?.gameEvent?.isBroadcasting,
        hostSessionID: data?.gameEvent?.hostSessionID,
        hostUserData: data?.gameEvent?.hostUserData || {},
      };
      setIsHostData(obj);

      const checkGame = data.gameEvent?.games?.find(
        (el) =>
          el?.players?.find((pl) => pl.id === user?.id)
          || el?.waitingPlayers?.find((pl) => pl.id === user?.id)
          || el?.viewers?.find((pl) => pl.id === user?.id)
      );

      if (gameId !== checkGame?.id && user?.role !== "dealer" && checkGame) {
        window.location.href = `/user/waiting-room/${checkGame?.id}`;
      }
    };

    const handleWaitingRoomFull = () => {
      setJoinError(true);
      setIsLoading(false);
    };

    const handleUpdateGame = (data) => {
      if (!game) {
        setGameData(data.game);
      }
      game = data.game;
      const obj = {
        host: data?.gameEvent?.host?.toLowerCase(),
        isBroadcasting: data?.gameEvent?.isBroadcasting,
        hostSessionID: data?.gameEvent?.hostSessionID,
        hostUserData: data?.gameEvent?.hostUserData,
      };
      setIsHostData(obj);
      setGameEvent(data?.gameEvent);
      const checkSpecInGame = data.gameEvent?.games?.find((el) =>
        el?.spectators?.find((pl) => pl.id === user?.id)
      );

      if (user?.role !== "dealer" && checkSpecInGame) {
        window.location.href = `/user/main-game/${checkSpecInGame?.id}`;
      }

      setWaitingPlayers(game?.waitingPlayers);

      if (
        data?.game?.lobbyPlayers?.find((el) => el.id === user.id)
        && data?.game?.videoTrack
      ) {
        if (
          localStorage.getItem("videoKey") === gameId
          && data?.game?.players?.find((el) => el.id === user?.id)
        ) {
          setShowIntro(false);
          // Add Waiting Room Video here.
          //  setShowCountDown(true);
          handleMoveToInside(gameId);
          //  window.location.href = `/user/main-game/${gameId}`;
        } else {
          // redirct on intro video  play page
          //  setShowIntro(true);
          if (game?.videoTrack) {
            localStorage.setItem("page", "waiting");
            if (data?.isHostAdded) {
              setShowIntroVideo(true);
            } else {
              setShowCountDown(true);
            }
            // navigate(`/user/intro-room/${gameId}`, {
            //  state: { from: "waitingroom" },
            // });
            // window.location.href = `/user/intro-room/${gameId}`;
          }
          localStorage.setItem("videoKey", gameId);
        }
      } else if (
        data?.game?.lobbyPlayers?.find((el) => el.id === user.id)
        && !data?.game?.videoTrack
      ) {
        localStorage.setItem("page", "");
        if (data?.isHostAdded) {
          // setShowIntroVideo(true);
          window.location.href = `/user/main-game/${gameId}`;
        } else {
          setShowCountDown(true);
        }

        // window.location.href = `/user/main-game/${gameId}`;
      } else if (data?.game?.players?.find((el) => el.id === user?.id)) {
        // window.location.href = `/user/main-game/${gameId}`;
        handleMoveToInside(gameId);
      } else if (data?.game?.viewers?.find((el) => el.id === user?.id)) {
        handleMoveToInside(gameId);
        // window.location.href = `/user/main-game/${gameId}`;
      } else if (data?.game?.spectators?.find((el) => el.id === user?.id)) {
        handleMoveToInside(gameId);
        // window.location.href = `/user/main-game/${gameId}`;
      }

      if (data?.profileUpdate) {
        setShowProfileModal(false);
      }

      const checkGame = data.gameEvent?.games?.find(
        (el) =>
          el?.players?.find((pl) => pl.id === user?.id)
          || el?.waitingPlayers?.find((pl) => pl.id === user?.id)
          || el?.viewers?.find((pl) => pl.id === user?.id)
      );

      if (gameId !== checkGame?.id && user?.role === "user" && checkGame) {
        window.location.href = `/user/waiting-room/${checkGame?.id}`;
      }
    };

    const handleNotFound = (data) => {
      if (
        data.message === "Game not found. Either game is finished or not exist"
      ) {
        navigate("*");
      }
    };

    const handleCleanup = () => {
      socket.off("updateGame");
      socket.off("wt-update");
    };

    socket.on("connect", handleConnect);
    socket.on("wt-update", handleWtUpdate);
    socket.on("waitingRoomFull", handleWaitingRoomFull);
    socket.on("updateGame", handleUpdateGame);
    socket.on("notFound", handleNotFound);

    if (game?.waitingPlayers?.find((pl) => pl.id === user?.id)) {
      localStorage.removeItem("timerCount");
    }
    localStorage.removeItem("dealerMuteAll");
    localStorage.removeItem("dealerMuteAllViewers");
    localStorage.removeItem("musicVolume");
    localStorage.removeItem("musicMuted");

    return handleCleanup;
  }, []);

  const chatByTableId = (allChats, tableId) => {
    if (!tableId) return allChats;
    if (allChats?.length > 0) {
      return allChats?.filter((el) => el.gameId === tableId);
    }
  };

  useEffect(() => {
    socket.on("newMessage", (data) => {
      //  setActualAllChats(data.chats);
      const tableViseChats = chatByTableId(data.chats, "");
      setChats(tableViseChats);
      //  setChatCount(data?.chats?.length);
    });

    return () => {
      socket.off("newMessage");
    };
  }, [gameEvent]);

  useEffect(() => {
    if (user) {
      socket.emit("checkTable", { gameId, userId: user?.id, waiting: true });
    }
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            socket.emit("checkTable", { gameId, userId: user?.id });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    socket.on("connect", () => {
      socket.emit("checkTable", { gameId, userId: user?.id });
    });
  }, []);
  const getJoin = async () => {
    // newCallObject = DailyIframe.createCallObject({
    //  dailyConfig: {
    //    useDevicePreferenceCookies: true,
    //  },
    // });
    // setCallObject(newCallObject);

    try {
      const meetToken = game?.waitingPlayers?.find(
        (el) => el.id === user?.id
      )?.meetingToken;
      //  console.log("meetToken", meetToken);
      //  console.log("callObject", callObject);

      if (meetToken) {
        const userName = user?.id;
        const meetingURL = `https://cloudpokernight.daily.co/w-${game?.gameEventId}`;
        // console.log("meetingURL", meetingURL);

        callObject
          .join({
            url: meetingURL,
            token: meetToken,
            userName,
            startVideoOff:
              JSON.parse(localStorage.getItem("isCameraOn")) !== null
                ? !JSON.parse(localStorage.getItem("isCameraOn"))
                : false,
            startAudioOff:
              JSON.parse(localStorage.getItem("isMicOn")) !== null
                ? !JSON.parse(localStorage.getItem("isMicOn"))
                : false,
            audioSource: JSON.parse(localStorage.getItem("mic"))?.value,
            videoSource: JSON.parse(localStorage.getItem("camera"))?.value,
          })
          .catch((err) => {
            console.error("Error joining room:", err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (game?.waitingPlayers?.length) {
      getJoin();
    }

    // return () => {
    //  newCallObject?.leave();
    // };
  }, [gameData]);

  const [showSidebar, setShowSidebar] = useState(false);
  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  //  const handleVideoClose = () => {
  //    localStorage.setItem("videoKey", gameId);
  //    setShowIntro(false);
  //    window.location.href = `/user/main-game/${gameId}`;
  //  };

  //  const handleVideoEnded = () => {
  //    localStorage.setItem("videoKey", gameId);
  //    setShowIntro(false);
  //    window.location.href = `/user/main-game/${gameId}`;
  //  };

  useEffect(() => {
    const introVideo = document?.getElementById("intro-video");
    if (introVideo && showIntro) {
      introVideo.play();
      introVideo.muted = !introVideo.muted;
    }
  }, [showIntro]);

  const handleCloseProfile = () => {
    setShowProfileModal(false);
    navigate(window.location.pathname);
  };
  useEffect(() => {
    if (isHostData?.host === user?.email) {
      setShowBroadcastControl(isHostData.isBroadcasting);
    }
    if (isHostData.isBroadcasting) {
      const tableData = waitingPlayers.find((player) => player.id === user.id);
      if (tableData.isAudio === true && isHostData?.host !== user?.email) {
        socket.emit("playerMicChange", {
          gameId,
          userId: user?.id,
          isAudio: false,
        });
      }
    }
  }, [isHostData.isBroadcasting]);

  const toggleLeavePopup = () => {
    setLeaveRoom(!leaveRoom);
  };

  const handleWaitingSidebar = () => {
    setSidebarExpand(!sidebarExpand);
  };

  return (
    <div className="waiting-room-content-container">
      <Sidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <WaitingRoomSideBar
        showSidebar={sidebarExpand}
        handleSidebar={handleWaitingSidebar}
        handleShowSetting={handleShowSetting}
        toggleLeavePopup={toggleLeavePopup}
        chats={chats}
      />
      <div className="container">
        {/* {callObject && ( */}
        {/* <DailyProvider dailyConfig={{ useDevicePreferenceCookies: true }}> */}
        <div
          className="waiting-room-content"
          style={{
            transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
              scaleValue / 100
            })`,
            left: sidebarExpand ? "46.3%" : "50%",
          }}
        >
          <div className="waiting-room-head">
            {/* <div className="waiting-logo">
                  <span onClick={handleSidebar} role="presentation">
                    <img src={logo} alt="logo" />
                  </span>
                </div> */}
            <div className="waiting-text">
              <h4>Waiting for more players to arrive</h4>
            </div>
            {!sidebarExpand && (
              <WaitingRoomAction
                toggleLeavePopup={toggleLeavePopup}
                handleShowSetting={handleShowSetting}
                sidebarExpand={sidebarExpand}
                setSidebarExpand={setSidebarExpand}
              />
            )}
          </div>

          {isLoading ? (
            <div className="loader">
              <Lottie options={LoaderAnimation} />
            </div>
          ) : (
            <WaitingRoomCamera
              waitingPlayers={waitingPlayers}
              isHost={isHostData?.host}
              isBroadcasting={isHostData?.isBroadcasting}
              isHostData={isHostData?.hostSessionID}
              hostUserData={isHostData?.hostUserData || {}}
              setLeaveRoom={setLeaveRoom}
              leaveRoom={leaveRoom}
              sidebarExpand={sidebarExpand}
              chats={chats}
            />
          )}

          {messages?.map((message) => {
            if (message.type === "error") {
              setTimeout(() => {
                setMessages((old) => old.filter((o) => o.id !== message.id));
              }, 4000);
              return (
                <ToastError
                  key={message.id}
                  message={message.msg}
                  closeAction={() =>
                    setMessages((old) => old.filter((o) => o.id !== message.id))}
                />
              );
            }
            return null; // added to satisfy React's requirement of returning a value in map
          })}
          {showBroadcastControl && !isHostData?.isBroadcasting && (
            <HostControlCall hostData={isHostData} />
          )}
          {(isHostData?.isBroadcasting || showIntroVideo) && (
            <HostBroadcast
              hostData={isHostData}
              isIntroVideo={showIntroVideo}
            />
          )}
          {/* {showIntroVideo && (
                <HostBroadcast hostData={isHostData} isIntroVideo />
              )} */}
        </div>
        {/* </DailyProvider> */}
        {/* )} */}
      </div>

      {/* <div
        className={`intro-video-popup ${
          showIntro && game?.videoTrack ? "show" : ""
        }`}
      >
        <div className="intro-video-wrapper testuser">
          <Button className="close-intro-video" onClick={handleVideoClose}>
            <CrossIcon />
          </Button>
          <video
            id="intro-video"
            width="1280"
            height="720"
            preload="auto"
            playsInline
            src={game?.videoTrack}
            controls
            muted
            onEnded={handleVideoEnded}
          />
        </div>
      </div> */}
      <JoinErrorModal
        joinError={joinError}
        handleJoinError={handleCloseJoinError}
      />
      <Modal
        show={showProfileModal}
        className="bar-room-setting-popup cpn-qr-popup"
        centered
        style={{ transform: `translate(-50%, 0%) scale(${scaleValue / 100})` }}
        onHide={handleCloseProfile}
      >
        <CreateProfileModal />
      </Modal>
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="bar-room-setting-popup main-game-setting"
        style={{
          transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
            scaleValue / 100
          })`,
        }}
        centered
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>
    </div>
  );
}
export default WaitingRoom;
