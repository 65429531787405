/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useVideoTrack, useAudioTrack, useDaily } from "@daily-co/daily-react";
import PropsTypes from "prop-types";
import dealer from "../../../assets/images/playerview/dealer-cover.png";
import AutomatedCommunityCard from "./automatedCommunityCard";
import UserContext from "../../../context/UserContext";
import CommunityCameraView from "./CommunityCameraView";
// import ReactionWinnerConfetti from "../../PlayerTileReaction/ReactionWinnerConfetti";
import MuteIcon from "../../../IconComponents/MuteIcon";
import SpeakerOffIcon from "../../../IconComponents/SpeakerOffIcon";

function CameraOne({ runningRound, player, gameData }) {
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();

  const {
    barroomVolume,
    speaker,
    pokerTableVolume,
    user,
    oneToOneRoom,
    // reactions,
  } = useContext(UserContext);
  //  const reaction = reactions.find(
  //    (el) => el.role === "dealer" && gameData?.dealer?.id === el.userId
  //  );
  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(gameData.dealer.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      if (isParticipantInCall) {
        if (isParticipantInCall.roomId.includes(user.id)) {
          audioRef.current.volume = pokerTableVolume;
        } else {
          audioRef.current.volume = 0.0;
        }
      } else if (isUserInCall) {
        audioRef.current.volume = 0.0;
      } else if (gameData?.viewers?.find((pl) => pl.id === player?.id)) {
        audioRef.current.volume = barroomVolume;
      } else {
        audioRef.current.volume = pokerTableVolume;
      }
      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    barroomVolume,
    pokerTableVolume,
    audioTrack.subscribed,
  ]);

  const getRunningRound = () => {
    switch (gameData?.runninground) {
      case 1:
        return "PRE-FLOP";
      case 2:
        return "FLOP";
      case 3:
        return "TURN";
      case 4:
        return "RIVER";
      case 5:
        return "SHOW-DOWN";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="dealer-cam--view">
        {remoteParticipant?.user_name !== user?.role ? (
          <audio
            ref={audioRef}
            autoPlay
            muted={remoteParticipant?.local || !speaker}
            id="dealer"
          />
        ) : (
          ""
        )}
        {remoteParticipant
        && !videoTrack?.isOff
        && videoTrack?.state === "playable" ? (
          <>
            <div className="player-camera-box single-dealer-cam">
              {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
              <video
                style={{ width: "100%" }}
                ref={videoRef}
                id="dealer-camera"
                autoPlay
                playsInline
                preload=""
                controls={false}
                muted
              />
              <div className="dealer-name-wrapper table-count-wrapper">
                <span>{`Table ${gameData?.tableNumber || 1}`}</span>
              </div>
              <div className="dealer-name-wrapper">
                <span>
                  {user?.role === "dealer"
                    ? `${user?.firstName}`
                    : `${gameData?.dealer?.firstName}`}
                </span>
                {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
                {audioTrack?.isOff ? <MuteIcon /> : ""}
              </div>
              {gameData?.runninground > 0 && gameData?.runninground < 6 ? (
                <div className="dealer-name-wrapper current-round-wrapper">
                  <span>{getRunningRound()}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <CommunityCameraView
              gameData={gameData}
              tracks={remoteParticipant.tracks}
            />
          </>
          ) : (
            <>
              <div className="player-camera-box">
                <img src={dealer} alt="" />
                {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
                <div className="dealer-name-wrapper table-count-wrapper">
                  <span>{`Table ${gameData?.tableNumber || 1}`}</span>
                </div>
                <div className="dealer-name-wrapper">
                  <span>
                    {user?.role === "dealer"
                      ? `${user?.firstName}`
                      : `${gameData?.dealer?.firstName}`}
                  </span>
                  {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
                  {audioTrack?.isOff ? <MuteIcon /> : ""}
                </div>
                <div className="dealer-name-wrapper multi-table-count">
                  <span>{`# ${gameData?.tableNumber || 1}`}</span>
                </div>
                {gameData?.runninground > 0 && gameData?.runninground < 6 ? (
                  <div className="dealer-name-wrapper current-round-wrapper">
                    <span>{getRunningRound()}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <AutomatedCommunityCard gameData={gameData} />
            </>
          )}
      </div>
      <div className="multi-table-dealer-view">
        <div className="player-camera-box">
          <img src={dealer} alt="" />
          {runningRound ? <Button>{getRunningRound()}</Button> : ""}
          <div className="dealer-name-wrapper table-count-wrapper">
            <span>{`Table ${gameData?.tableNumber || 1}`}</span>
          </div>
          <div className="dealer-name-wrapper">
            <span>
              {user?.role === "dealer"
                ? `${user?.firstName}`
                : `${gameData?.dealer?.firstName}`}
            </span>
            {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
            {audioTrack?.isOff ? <MuteIcon /> : ""}
          </div>
          <div className="dealer-name-wrapper multi-table-count">
            <span>{`# ${gameData?.tableNumber || 1}`}</span>
          </div>
        </div>
        <AutomatedCommunityCard gameData={gameData} />
      </div>
    </>
  );
}

CameraOne.defaultProps = {
  runningRound: 0,
  player: null,
  gameData: {},
};

CameraOne.propTypes = {
  runningRound: PropsTypes.number,
  player: PropsTypes.object,
  gameData: PropsTypes.object,
};

export default CameraOne;
